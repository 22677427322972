import React, {Component} from "react";

class Home extends Component {
    render() {
        return (
        <div>
             <h2>Hello</h2>
             <p>Lots of text here.  What can we do?</p>
             <p>Even more text.</p>
             </div>


           
        );
    }
}

export default Home;