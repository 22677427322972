import React, {Component} from "react";

class Stuff extends Component {
    render() {
        return (
        <div>
             <h2>Stuff</h2>
             <p>More stuff?</p>
             <p>Lots more</p>
             </div>


           
        );
    }
}

export default Stuff;