import React, {Component} from "react";

class Contact extends Component {
    render() {
        return (
        <div>
             <h2>Stuff</h2>
             <p>Hell, yeah.</p>
             <p>Whats up?</p>
             </div>


           
        );
    }
}

export default Contact;